import React from 'react'
import Layout from '../components/layout'

import { Image } from '../components/Texts'
import not_found_img from '../assets/images/notfound.webp'

const NotFoundPage = () => (
  <Layout lang="en" title="Page Not Found" rootProps={{ className: 'page 404' }}>
    <div id="main" className="alt">
      <section id="one" className="content container notfound-container">
        <div className="row">
          <div className="col-5 col-12-medium image">
            <Image image={not_found_img} title="Not found" alt="Not found" />
            <p className="credits">
              Photo by 
              <a href="https://unsplash.com/@nate_dumlao?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer"> Nathan Dumlao </a>
             </p> 
          </div>
          <div className="col-6 col-12-medium text">
            <h1>We have looked everywhere.</h1>
            <p>It seems that the page you are looking for doesn’t exist.</p>
            </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
